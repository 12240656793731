import React from 'react';
import {Link} from 'react-router-dom';
import {Carousel} from 'react-bootstrap';
import ReactGA from 'react-ga';

const Home = () => {

    function logGA(from) {
        ReactGA.event({
            category: 'Click',
            action: 'Register my purchase from ' + from
        });
    }

    function logGABack() {
        ReactGA.event({
            category: 'Click',
            action: 'Back to smythstoys.com'
        });
    }

    return (
        <>
            <div id="home-header">

                <div className={"btns"}>
                    <Link onClick={() => {
                        logGA('header')
                    }} className="cta_black" to={"/registermypurchase"}>register my purchase</Link>
                    <a onClick={() => {
                        logGABack()
                    }} className="cta_back" href={"https://www.smythstoys.com/uk/en-gb/lego-shop"} target="_blank">
                        Go back to Smyths Toys
                    </a>
                </div>

                <div className="info">
                    *Offer valid for purchases of £25+ on LEGO sets (excluding video games) made between 23/09/2022 and
                    30/09/2022, at a Smyths Toys store in the UK (excluding NI) or online at&nbsp;
                    <a href={"https://www.smythstoys.com/uk/en-gb/lego-shop"}
                       target={"_blank"}>smythstoys.com</a>. Limited to the first 20,000 entries. Gifts are chosen at
                    random. See full Terms and Conditions&nbsp;
                    <a href="/file/terms-conditions.pdf"
                       target={"_blank"}>here</a>.&nbsp;<br className={"d-block d-md-none"} />
                    Non-contractual visuals.
                </div>
            </div>

            <div className={"strate"} id={"homeStrate1"}>

                <h2 id={"howtoenter"}><span className={"middle"}>how</span> <span>to</span> enter?</h2>

                <div className={"carouselHtE"}>
                    {createCarouselHTE()}

                    <div className={"cta_bottom"}>
                        <Link onClick={() => {
                            logGA('how to enter')
                        }} className="cta_black" to={"/registermypurchase"}>register my purchase</Link>
                    </div>
                </div>

                <div className={"carouselSG"}>
                    <div className={"gift_top"}></div>

                    <h3 id={"gifts"}><span>the</span> <span className={"middle"}>surprise</span><br className={"d-bloc d-md-none"} /> gifts</h3>

                    {createCarouselSG()}

                    <div className={"cta_bottom"}>
                        <Link onClick={() => {
                            logGA('rewards')
                        }} className="cta_black" to={"/registermypurchase"}>register my purchase</Link>
                    </div>

                    <div className={"gift_bottom"}></div>
                </div>

            </div>
        </>
    );
};

const createCarouselHTE = () => {
    const content = [
        "<b>Spend £25+ on LEGO<sup>®</sup> sets<sup class='blue'>*</sup></b> between 23/09/2022 and 30/09/2022 at a Smyths Toys store in the UK (excluding NI) or online at <a href='https://www.smythstoys.com/uk/en-gb/lego-shop' target='_blank' rel='noreferrer'>smythstoys.com</a>.<div class='precision'>*Excludes videogames.</div>",
        "Please <b>complete the registration form <a href='/registermypurchase'>here</a></b> and <b>upload a clear image of your receipt</b> to discover your surprise!<br><br>Register by the 07/10/2022 and the image of your receipt must show:<br><ul><li><span>The LEGO® set(s) bought</span></li><li><span>The date of purchase</span></li><li><span>The Smyths Toys logo</span></li><li><span>£25+ spent in 1 transaction</span></li></ul>",
        "You will then receive an email <b>within 7 working days</b>, confirming whether your entry is valid or not.<div class='precision'>For more information regarding the requirements<br>for my entry to be validated, see <a href='/faq'>FAQ’S</a>.</div>",
        "<div class='center'><b>Enjoy your surprise gift!</b></div>"
    ];

    return createCarousel(content);
}

const createCarouselSG = () => {
    const content = [
        "<div class='title'>Incredible family stay at The LEGOLAND<div class='rs'></div> Windsor Resort</div><div class='subtitle'>with a 2-day theme park ticket&nbsp;<br class='d-block d-md-none'>for 2 adults and<br class='d-none d-md-block'> 2 children!</div><div class='desc'>See full Terms and Conditions <a href='/file/terms-conditions.pdf' target='_blank'>here</a>.<br>&copy; 2022 The LEGO Group.</div>",
        "<div class='title'>LEGO<div class='rs'></div> SETS</div><div class='desc'>LEGO<sup>®</sup> Friends, LEGO<sup>®</sup> City, LEGO® DUPLO<sup>®</sup>, <br class='d-block d-md-none'>LEGO<sup>®</sup> Minecraft,<br class='d-none d-md-block'> LEGO<sup>®</sup> Ninjago, LEGO<sup>®</sup> Creator<sup>™</sup>.</div>",
        "<div class='title'>LEGO<div class='rs'></div> MOSAIC MAKER SETS</div><div class='subtitle min'>to recreate your favourite photos as a mosaic!</div>",
        "<div class='title'>SPORT OR LEISURE<br class='d-block d-md-none'> ACTIVITIES</div><div class='subtitle'>for children and adults!</div><div class='desc'>Pick from a number of different activities across UK such as go tennis, football, gymnastics and so much more…</div>"
    ];

    return createCarousel(content);
}

const createCarousel = (content) => {
    let items = [];

    for (let i = 0; i <= 3; i++) {
        items.push(
            <Carousel.Item key={"Item" + i}>
                <div className={"item item_" + (i + 1)}>
                    <div className={"content"} dangerouslySetInnerHTML={{__html: content[i]}}></div>
                </div>
            </Carousel.Item>
        );
    }

    return (
        <Carousel controls={true} interval={null} indicators={true}>
            {items}
        </Carousel>
    );
};

export default Home;
