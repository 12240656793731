import React from 'react';
import Cookies from 'universal-cookie';
import ReactGA from "react-ga";

class CookieBanner extends React.Component {

    constructor(props) {
        super(props)

        this.cookies = new Cookies();

        this.cookie_accept_all = this.cookie_accept_all.bind(this)
        this.cookie_reject_all = this.cookie_reject_all.bind(this)
        this.cookie_personalize = this.cookie_personalize.bind(this)
        this.cookie_set_cookie = this.cookie_set_cookie.bind(this)

        //affichage ou non du bandeau plus chargement des preferences
        this.show_cookie_banner = this.cookies.get('pn_cookie_accept') === undefined ? true : !this.cookies.get('pn_cookie_accept')
        this.cookie_pref = this.cookies.get('pn_cookie_pref')

        if (this.cookie_pref !== undefined)
            this.cookie_set_cookie(this.cookie_pref.analytics)
    }

    //affichage de la banière
    cookie_show_banner() {
        document.getElementById("cookie_banner").classList.remove("hide")
    }

    cookie_hide_banner() {
        document.getElementById("cookie_banner").classList.add("hide")
    }

    //accepter tout les cookies
    cookie_accept_all() {
        this.cookie_hide_banner()

        //activation des cookies
        this.cookie_set_cookie(true);

        //set du cookie d'affichage du bandeau
        this.cookies.set('pn_cookie_pref', {"analytics": true}, {path: '/'});
        this.cookies.set('pn_cookie_accept', true, {path: '/'});
    }

    //refuser tout les cookies
    cookie_reject_all() {
        this.cookie_hide_banner()

        //activation des cookies
        this.cookie_set_cookie(false);

        //set du cookie d'affichage du bandeau
        this.cookies.set('pn_cookie_accept', true, {path: '/'});

        //mise a false des cookies
        this.cookies.set('pn_cookie_pref', {"analytics": false}, {path: '/'});
    }

    //validation des preferences
    cookie_personalize() {
        let analytics = document.getElementById("cookie_analytics").checked;

        this.cookie_set_cookie(analytics);

        //save des preferences dans un cookie
        this.cookies.set('pn_cookie_pref', {"analytics": analytics}, {path: '/'});
        this.cookies.set('pn_cookie_accept', true, {path: '/'});

        this.cookie_hide_banner()
        this.setState({show_modal: false})
    }

    //activation/desactivation des cookies
    cookie_set_cookie(analytics) {

        if (analytics === true) {

            //console.log(process.env.REACT_APP_GA);

            ReactGA.initialize(
                [{trackingId: process.env.REACT_APP_GA}],
                {debug: false}
            );
        }

    }

    render() {

        let cookie_banner_class

        if (this.show_cookie_banner)
            cookie_banner_class = ""
        else
            cookie_banner_class = "hide"

        return (
            <>
                <div id="cookie_banner" className={cookie_banner_class}>
                    <div className="inner">
                        <div className={"image"}></div>
                        <div className="inner-aw">
                            <p>We use cookies to personalise your experience and to analyse our traffic.</p>
                            <p>For more information on the use of cookies and your rights, please access our <a
                                href="/file/privacy-cookies-policy.pdf"
                                target={"_blank"}>Privacy and Cookies Policy</a>.
                            </p>
                            <div className="btns">
                                <button id="cookie_accept_all" className="cta bordered"
                                        onClick={this.cookie_accept_all}>allow cookies
                                </button>
                                <button id="cookie_accept_all" className="cta bordered"
                                        onClick={this.cookie_reject_all}>deny cookies
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CookieBanner;
